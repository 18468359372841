import { Card } from '@troon/ui';
import { Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { dayTimeToDate, formatDateTime, formatDateTimeAttribute } from '../modules/date-formatting';
import { ReservationState, gql } from '../graphql';
import type { ReservationFragment } from '../graphql';

type Props = ReservationFragment & {
	showAction?: boolean;
};

export function ReservationCard(props: Props) {
	return (
		<Card
			appearance={props.isCheckInWindow && props.checkInEnabled ? 'primary' : 'secondary'}
			action={
				props.showAction !== false
					? props.isCheckInWindow && props.checkInEnabled
						? 'Check in now'
						: 'Details'
					: undefined
			}
			href={`/reservation/${props.id}${props.isCheckInWindow && props.checkInEnabled ? '/check-in' : ''}`}
			icon="calendar"
			image={{
				src: props.facility.metadata?.hero?.url,
				sizes: [[204, 153]],
			}}
		>
			<div class="flex flex-col gap-y-2">
				<h2 class="flex flex-col">
					<span class="text-lg font-semibold">{props.facility.name}</span>
				</h2>
				<div class="order-first flex flex-row flex-wrap items-center gap-2">
					<Show when={props.state === ReservationState.Cancelled}>
						<span class="self-start rounded bg-neutral px-2 py-1 text-sm font-semibold uppercase text-black">
							Cancelled
						</span>
					</Show>
					<time
						datetime={formatDateTimeAttribute(dayTimeToDate(props.dayTime))}
						class="text-sm font-semibold text-brand"
					>
						{formatDateTime(dayTimeToDate(props.dayTime))}
					</time>
				</div>
				<p>
					{props.facility.metadata?.address?.city}, {props.facility.metadata?.address?.state}
				</p>
				<ul class="flex flex-wrap gap-x-4 text-sm text-neutral-700">
					<li class="flex items-center gap-1">
						<Icon name="users" />
						{props.playerCount}
					</li>
					<li class="flex items-center gap-1">
						<Icon name="flag" />
						{props.holeCount} holes
					</li>
					<Show when={props.includesCart}>
						<li class="flex items-center gap-1">
							<Icon name="golf-cart" />
							Cart included
						</li>
					</Show>
				</ul>
			</div>
		</Card>
	);
}

gql(`
fragment Reservation on Reservation {
  id
  teeTimeId
  dayTime {
    ...DayTime
  }
	includesCart
	playerCount
	holeCount
	isCheckInWindow
	checkInEnabled
	state
	facility {
		name
		metadata {
			hero {
				url
			}
			address {
				city
				state
			}
		}
	}
  course {
    name
  }
}`);
